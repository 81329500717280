<template>
  <div class="shadow-md rounded-lg mx-5 px-4 py-3 mt-4"
    :class="isLightMode ? 'bg-white text-black' : 'bg-[#2B2926] text-white'">
    <div class="text-[24px] font-bold">
      {{ eventName }}
    </div>
    <div class="flex flex-col">
      <div v-for="(item, index) in dateTime" :key="index" class="flex flex-row pt-[24px]">
        <div class="col-span-3 text-center rounded-t-md w-[70px]">
          <div v-if="getDay(item.from_date) == getDay(item.to_date)" class="rounded-t-md text-xs py-1 px-1" :class="isLightMode ? 'bg-[#FEC629]' : 'bg-[#78634F]'">
            {{ getDay(item.from_date) }}
          </div>
          <div v-else class="rounded-t-md text-xs py-1 px-1" :class="isLightMode ? 'bg-[#FEC629]' : 'bg-[#78634F]'">
            {{ getDay(item.from_date) }} - {{ getDay(item.to_date) }}
          </div>

          <div
            class="rounded-b-md text-sm font-medium leading-4 py-1 px-1 h-[40px] flex justify-center items-center" :class="isLightMode ? 'bg-[#ECEBEB]':'bg-[#3C3A36]'">
            {{ displayDate(item.from_date, item.to_date) }}
          </div>
        </div>
        <div class="flex flex-col">
          <div v-for="(range, index) in item.time_range" :key="index"
            class="text-base font-bold uppercase flex justify-center items-center ml-[20px]">
            {{ displayTiming(range.from_time, range.to_time) }}
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 text-center font-bold text-sm grid gap-4" :class="sharingUrl && downloadAsset ? 'grid-cols-2' : 'grid-cols-1'">
      <div v-if="sharingUrl" @click="share()" class="rounded-full inline-block w-full border-2 py-2 flex justify-center gap-x-2" :class="isLightMode ? 'border-[#21201E]' : 'border-[#ECEBEB]'">
        <img src="../../../public/assets/icons/event-page/share-icon.svg" :class="{ 'invert-icon': !isLightMode }" />
        <div>Share</div>
      </div>
      <a v-if="downloadAsset" :href="downloadAsset" class="rounded-full inline-block w-full border-2 py-2 flex justify-center gap-x-2" :class="isLightMode ? 'border-[#21201E]' : 'border-[#ECEBEB]'">
        <img src="../../../public/assets/icons/event-page/download-icon.svg" :class="{ 'invert-icon': !isLightMode }" />
        <div>Download</div>
      </a>
    </div>
  </div>

</template>
<script>
import { Share } from "@capacitor/share";
import { mapState } from "vuex";
export default {
  name: "EventDetailsSection",
  props: {
    eventName: {
      type: String,
      default: "",
    },
    downloadAsset: {
      type: String,
      default: "",
    },
    sharingUrl: {
      type: String,
      default: "",
    },
    dateTime: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState("eventPage", ["isLightMode"]),
  },
  methods: {
    getDay(date) {
      const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
      return days[new Date(date).getDay()];
    },
    displayDate(from_date, to_date) {
      if (from_date === to_date) {
        return `${new Date(from_date).getDate()} ${new Date(from_date).toLocaleString(
          "default",
          { month: "short" }
        )}`;
      }
      return `${new Date(from_date).getDate()} - ${new Date(to_date).getDate()} ${new Date(
        to_date
      ).toLocaleString("default", { month: "short" })}`;
    },
    displayTiming(from_time, to_time) {
      const fromTime = from_time.split(":");
      let fromTimeHour = fromTime[0];
      const fromTimeMinute = fromTime[1];
      const fromPeriod = fromTimeHour >= 12 ? "PM" : "AM";
      const toTime = to_time.split(":");
      let toTimeHour = toTime[0];
      const toTimeMinute = toTime[1];
      const toPeriod = toTimeHour >= 12 ? "PM" : "AM";
      if (fromTimeHour > 12) {
        fromTimeHour = fromTimeHour - 12;
      }
      if (toTimeHour > 12) {
        toTimeHour = toTimeHour - 12;
      }
      return `${fromTimeHour}.${fromTimeMinute}${fromPeriod} - ${toTimeHour}.${toTimeMinute}${toPeriod}`;
    },
    async share() {
      await Share.share({
        title: this.eventName,
        url: this.sharingUrl,
      });
    }
  },
};
</script>

<style>
.invert-icon {
  filter: invert(6%) sepia(6%) saturate(972%) hue-rotate(2deg) brightness(97%) contrast(84%);
}
</style>