<template>
    <div class="relative h-screen w-screen bg-white">
       <div class="z-0 h-1/3 w-screen">
             <Thumbnail class="h-full w-full object-fill"/>
        </div>  
        <!--Transparent to white overlay-->
        <div class="absolute z-10 -mt-8 h-8 w-screen bg-gradient-to-b from-transparent to-white">
        </div>
        <div class="absolute z-20 left-0 right-0 flex justify-center h-screen -mt-8">
            <AnnouncementSection body-text="Registration closes on Friday 30 Sep, 23:59" class=""/>
        </div>   
    </div>
</template>

<script>
import Thumbnail from "@/components/EventPage/EventPageThumbnail.vue";
import AnnouncementSection from "@/components/EventPage/AnnouncementSection.vue";
export default{
    components:{
        Thumbnail,
        AnnouncementSection,
    }
};
</script>
