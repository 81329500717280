<template>
  <div class="rounded-[10px] shadow-md mx-5 px-4 py-3 mt-4"
    :class="{ 'bg-[#2B2926]': !isLightMode, 'bg-white': isLightMode }">
    <div class="flex flex-row">
      <img src="../../../public/assets/icons/event-page/location-icon.svg" alt="Location Icon" class="mr-2"
        :class="{ 'invert-icon': !isLightMode }">
      <p class="font-bold text-[18px] mt-[4px] mb-[4px]">
        {{ locationName }}
      </p>
    </div>
    <div v-if="locationImgUrl">
      <img :src="locationImgUrl" alt="Location Thumbnail" class="rounded-[10px]">
    </div>
    <div v-else>
      <img src="../../../public/assets/images/event-page/location_details_default_thumbnail.png" alt="Location Default"
        class="rounded-[10px]">
    </div>
    <p class="my-[12px]">
      {{ locationBody }}
    </p>
    <a class="rounded-full border-[1px] px-[8px] py-[4px] w-fit flex flex-row" :class="{ 'border-white': !isLightMode, 'border-[#2B2926]': isLightMode } " :href="locationUrl"
      target="_blank">
      <p>Open Map</p>
      <img src="../../../public/assets/icons/event-page/open-map-icon.svg" alt="Open Map Icon" class="ml-2"
        :class="{ 'invert-icon': !isLightMode }">
    </a>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  name: 'LocationDetails',
  props: {
    locationImgUrl: {
      type: String,
      default: "",
    },
    locationName: {
      type: String,
      default: "",
    },
    locationBody: {
      type: String,
      default: "",
    },
    locationUrl: {
      type: String,
      default: "",
    }
  },
  computed: {
    ...mapState("eventPage", ["isLightMode"]),
  },
}
</script>

<style>
.invert-icon {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(276deg) brightness(102%) contrast(104%);
}
</style>