<template>
  <div>
    <img :src="thumbnailUrl" class="w-screen" />
    <!--Transparent to white overlay-->
    <div
      class="absolute -mt-8 h-8 w-screen bg-gradient-to-b from-transparent"
      :class="!isLightMode ? 'to-[#3c3a36]' : 'to-white'"
    ></div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "EventPageThumbnail",
  props: {
    thumbnailUrl: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState("eventPage", ["isLightMode"]),
  },
};
</script>
