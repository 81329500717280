<template>
  <ion-page>
    <ion-header>
      <Header @openProfileModal="showingProfileModal = true" />
      <!-- <ButtonsCard
        class="px-[24px] lg:hidden"
        :class="$route.path == '/home' ? ' ' : 'hidden'"
      /> -->
    </ion-header>
    <ProfileModal v-if="showingProfileModal" @close="closeProfileModal" />
    <ion-content
      :id="id"
      fullscreen="false"
      class="master"
      :scroll-events="enableScrollEvent"
      @ionScroll="onScroll"
    >
      <main class="lg:flex h-full lg:h-auto">
        <SideBar class="shrink-0" @open-modal="toggleLogoutPopup()" />
        <slot class="flex-1 pb-16 lg:pb-0" test="test"> </slot>
      </main>
    </ion-content>
    <ion-footer>
      <ion-toolbar
        class="bg-white h-[68px] lg:hidden box-shadow"
        :class="{ 'h-[85px]': isIos() }"
      >
        <BottomNav />
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>
<script>
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonFooter,
  isPlatform,
} from "@ionic/vue";
import { mapGetters, mapState } from "vuex";
import { StatusBar } from "@capacitor/status-bar";
import BottomNav from "@/components/common/BottomNav";
import SideBar from "../components/common/Sidebar.vue";
import Header from "../components/common/Header.vue";
import ProfileModal from "@/components/modals/Profile";
import ButtonsCard from "@/components/HomePage/ButtonsCard.vue";
import { isIosNativeOnly } from "@/utils/platform-check";

export default {
  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonFooter,
    BottomNav,
    SideBar,
    Header,
    ButtonsCard,
    ProfileModal,
    // NotificationPopup,
    // ExperienceModal,
    // PhotoDownloadModal
  },
  props: {
    pageTitle: {
      type: String,
      default: "",
    },
    enableScrollEvent: {
      type: Boolean,
      default: false,
    },
    noLogin: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "ion-content",
    },
  },
  data() {
    return {
      showLogoutPopup: false,
      showPhotoPopup: false,
      showExpModal: false,
      selectedExp: null,
      showExpButton: false,
      windowWidth: null,
      // showingProfileModal: false,
    };
  },
  computed: {
    ...mapState("lookups", ["pastoralMapping"]),
    ...mapGetters("profile", ["pastoralRoleId"]),
    ...mapState("profile", ["showingProfileModal"]),
    showHeader() {
      return !this.$route.path.includes("home");
    },
  },
  watch: {
    async windowWidth(width) {
      if (width >= 1024) {
        await this.$store.commit("profile/setShowingProfileModal", false);
      }
    },
    showingProfileModal(isShowing) {
      if (isShowing) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
    },
    async pastoralMapping(pastoralMapping) {
      if (this.pastoralRoleId) {
        const isLeader = pastoralMapping[this.pastoralRoleId] >= 6;
        await this.$store.dispatch("profile/updateIsLeader", isLeader);
        await this.$store.dispatch(
          "calendar/updateIsLeadersCalendar",
          isLeader
        );
      }
    },
    async pastoralRoleId(pastoralRoleId) {
      if (pastoralRoleId && this.pastoralMapping) {
        const isLeader = this.pastoralMapping[pastoralRoleId] >= 6;
        await this.$store.dispatch("profile/updateIsLeader", isLeader);
        await this.$store.dispatch(
          "calendar/updateIsLeadersCalendar",
          isLeader
        );
      }
    },
  },
  async mounted() {
    if (!this.noLogin) {
      // await StatusBar.setOverlaysWebView({ overlay: true });
      await this.$store.dispatch("profile/fetchProfile");
      // await this.$store.dispatch("profile/fetchPastoralAbbrevMapping");
      await this.$store.dispatch("profile/fetchMinistryStatus");
      await this.$store.dispatch("lookups/fetchProfileDropdowns");
      await this.$store.dispatch("lookups/fetchPastoralMapping");
      // await this.$store.dispatch("lookups/fetchCgMapping"),
      // await this.$store.dispatch("lookups/fetchZoneCgMapping");
    }
  },
  methods: {
    onScroll(event) {
      this.$emit("on-scroll", event);
    },
    isIos() {
      return isIosNativeOnly();
    },
    async closeProfileModal() {
      await this.$store.commit("profile/setShowingProfileModal", false);
    },
  },
};
</script>
<style scoped>
ion-toolbar {
  --border-color: transparent;
  --background: transparent;
  --ion-color-base: transparent !important;
}

ion-content.master {
  --padding-bottom: 0px;
  --offset-top: -10% !important;
}

.footer-md::before {
  display: none;
}

.box-shadow {
  box-shadow: 0px 1px 25px 0px #0000001a;
}
</style>
