<template>
  <router-link
    v-if="!isExternal"
    class="text-center py-[8px] px-[4px]"
    :class="isActive ? 'text-[#6245F4]' : 'text-[#99A4B2]'"
    :to="to"
    @click.native="clickEvent()"
  >
    <component :is="icon" class="inline" :is-active="isActive" />
    <span class="block text-[14px] leading-[20px] text-center">
      {{ label }}
    </span>
  </router-link>
  <a
    v-else
    class="text-center py-[8px] px-[4px] text-[#99A4B2]"
    :href="to"
    target="_blank"
  >
    <component :is="icon" class="inline" color="#99A4B2" />
    <span class="block text-[14px] leading-[20px] text-center">
      {{ label }}
    </span>
  </a>
</template>

<script>
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { mapGetters, mapState } from "vuex";
import { isPlatform } from "@ionic/vue";

export default {
  props: {
    isExternal: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: null,
      required: true,
    },
    label: {
      type: String,
      default: null,
      required: true,
    },
    icon: {
      type: Object,
      default: null,
      required: true,
    },
  },
  computed: {
    ...mapGetters("profile", ["cgName", "age", "pastoralStatus"]),
    ...mapState("profile", ["id"]),

    isActive() {
      return this.$route.path === this.to;
    },
  },
  methods: {
    clickEvent() {
      console.log("clickevent here...");
      const isNative = isPlatform("android") || isPlatform("ios");
      const isNativeBrowser = isPlatform("mobileweb");
      if (isNative && !isNativeBrowser) {
        FirebaseAnalytics.logEvent({
          name: "click_button",
          params: {
            button_name: this.label,
            page: "bottomNav",
            member_id: this.id,
            cg: this.cgName,
            age: this.age,
            pastoral_status: this.pastoralStatus,
          },
        });
      }
    },
  },
};
</script>
