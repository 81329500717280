<template>
  <ion-page>
    <ion-header>
      <Header @openProfileModal="showingProfileModal = true" />
      <!-- <ButtonsCard
        class="px-[24px] lg:hidden"
        :class="$route.path == '/home' ? ' ' : 'hidden'"
      /> -->
    </ion-header>
    <ProfileModal v-if="showingProfileModal" @close="closeProfileModal" />
    <Loading class="w-full mx-auto" v-if="isLoadingWhitelist" />
    <ion-content
      v-else-if="resource"
      :id="id"
      fullscreen="false"
      class="master"
      :scroll-events="enableScrollEvent"
    >
      <main class="lg:flex h-100vh lg:h-auto background-img">
        <SideBar class="shrink-0" @open-modal="toggleLogoutPopup()" />
        <slot class="flex-1 pb-16 lg:pb-0" test="test"> </slot>
      </main>
    </ion-content>
    <div v-else class="password-background-img">
      <PasswordEntry
        class="mt-[112px] h-[calc(100vh-180px)] lg:h-[100vh] w-[248px]"
        :is-loading="isLoadingPassword"
        :res-status="resStatus"
        :button-text="buttonText"
        @enter-password="getResourceWithPassword"
      />
    </div>
    <ion-footer>
      <ion-toolbar
        class="bg-white h-[68px] lg:hidden box-shadow"
        :class="{ 'h-[85px]': isIos() }"
      >
        <BottomNav />
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>
<script>
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonFooter,
  isPlatform
} from "@ionic/vue";
import { mapState } from "vuex";
import BottomNav from "@/components/common/BottomNav";
import SideBar from "../components/common/Sidebar.vue";
import Header from "../components/common/Header.vue";
import ProfileModal from "@/components/modals/Profile";
import ButtonsCard from "@/components/HomePage/ButtonsCard.vue";
import { isIosNativeOnly } from "@/utils/platform-check";
import Loading from "@/components/common/LoadingState.vue";
import PasswordEntry from "@/components/ProtectedPage/PasswordEntry";
import axios from "axios";

export default {
  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonFooter,
    BottomNav,
    SideBar,
    Header,
    ButtonsCard,
    ProfileModal,
    Loading,
    PasswordEntry
  },
  props: {
    pageTitle: {
      type: String,
      default: ""
    },
    enableScrollEvent: {
      type: Boolean,
      default: false
    },
    noLogin: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: "ion-content"
    }
  },
  data() {
    return {
      showLogoutPopup: false,
      showPhotoPopup: false,
      showExpModal: false,
      selectedExp: null,
      showExpButton: false,
      windowWidth: null,
      isLoadingPassword: false,
      isLoadingWhitelist: true,
      resource: undefined,
      resStatus: 0,
      buttonText: "Access Letters",
      slug: "",
      routeName: ""
    };
  },
  computed: {
    ...mapState("user", ["token"]),
    ...mapState("profile", ["showingProfileModal"])
  },
  watch: {
    async windowWidth(width) {
      if (width >= 1024) {
        await this.$store.commit("profile/setShowingProfileModal", false);
      }
    },
    showingProfileModal(isShowing) {
      if (isShowing) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
    }
  },
  async mounted() {
    if (!this.noLogin) {
      // await StatusBar.setOverlaysWebView({ overlay: true });
      await this.$store.dispatch("profile/fetchProfile");
      await this.$store.dispatch("lookups/fetchProfileDropdowns");
      await this.$store.dispatch("lookups/fetchPastoralMapping");
      try {
        this.routeName = this.$route.path.substring(1, this.$route.path.length);
        if (this.routeName.includes("/")) {
          this.slug = this.routeName.replace("/", "-");
          // just check if whitelisted, no data needed
          await axios.get(
            process.env.VUE_APP_API_URL +
              `/cms/protected/${this.slug}/whitelist`,
            {
              headers: {
                Authorization: `Bearer ${this.token}`
              }
            }
          );
        }
        // check whitelisted for main resource
        const response = await axios.get(
          process.env.VUE_APP_API_URL + "/cms/protected/hogcfd/whitelist",
          {
            headers: {
              Authorization: `Bearer ${this.token}`
            }
          }
        );
        this.resource = response.data;
      } catch (e) {
        if (
          e.response &&
          (e.response.status === 403 || e.response.status === 500)
        ) {
          this.$router.push({ path: "/" });
        }
      } finally {
        this.$store.dispatch("protected/updateCurrResource", this.resource);
        this.isLoadingWhitelist = false;
      }
    }
  },
  methods: {
    isIos() {
      return isIosNativeOnly();
    },
    async getResourceWithPassword(password) {
      if (this.isLoadingPassword) return;
      this.isLoadingPassword = true;
      try {
        console.log("in getResourceWithPassword");
        console.log(this.routeName);
        if (this.routeName.includes("/")) {
          console.log("in IF Statement");
          this.slug = this.routeName.replace("/", "-");
          // just check if password is correct, no data needed
          await axios.post(process.env.VUE_APP_API_URL + "/cms/protected", {
            method: "post",
            data: { password, slug: this.slug },
            headers: {
              Authorization: `Bearer ${this.token}`
            }
          });
        }
        console.log("in Outside Statement");
        // check password works for main resource
        const response = await axios.request(
          process.env.VUE_APP_API_URL + "/cms/protected",
          {
            method: "post",
            data: { password, slug: "hogcfd" },
            headers: {
              Authorization: `Bearer ${this.token}`
            }
          }
        );
        this.resource = response.data;
        console.log(this.resource);
        this.resStatus = response.status;
        console.log(this.resStatus);
      } catch (e) {
        if (e.response && e.response.status === 401) {
          this.resStatus = e.response.status;
        }
      } finally {
        this.$store.dispatch("protected/updateCurrResource", this.resource);
        this.isLoadingPassword = false;
      }
    },
    async closeProfileModal() {
      await this.$store.commit("profile/setShowingProfileModal", false);
    }
  }
};
</script>
<style scoped>
ion-toolbar {
  --border-color: transparent;
  --background: transparent;
  --ion-color-base: transparent !important;
}

ion-content.master {
  --padding-bottom: 0px;
  --offset-top: -10% !important;
}

.footer-md::before {
  display: none;
}

.box-shadow {
  box-shadow: 0px 1px 25px 0px #0000001a;
}
.background-img {
  background-image: url("../../public/assets/images/hogcfd/fd-background-image.png");
  width: 100vw;
}
.password-background-img {
  background-image: url("../../public/assets/images/hogcfd/fd-background-image.png");
}
</style>
