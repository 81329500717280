<template>
  <div
    class="shadow-md rounded-lg mx-5 px-4 py-3 mt-4"
    :class="isLightMode ? 'bg-white' : 'bg-[#2B2926]'"
  >
    <div class="font-semibold text-[18px]">
      {{ title }}
    </div>
    <div v-if="targetDemo" class="flex flex-row pt-[8px]">
      <img src="../../../public/assets/icons/event-page/profile-icon.svg" 
      :class="{ 'invert': isLightMode }"/>
      <div class="text-sm mx-[4px]">
        {{ targetDemo }}
      </div>
    </div>
    <div class="text-sm mt-2 pt-[8px]">
      {{ bodyText }}
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "DescriptionSection",
  props: {
    title: {
      type: String,
      default: "",
    },
    bodyText: {
      type: String,
      default: "",
    },
    targetDemo: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState("eventPage", ["isLightMode"]),
  },
};
</script>
