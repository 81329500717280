<template>
  <div class="sticky w-full h-[80px] bottom-0 items-center justify-center flex backdrop-blur-sm bg-[#00000020]">
    <div class="bg-[#FEC629] rounded-[20px] my-auto w-[90%] text-center font-bold h-12 py-3 text-xl text-black">    
      <a :href="registerUrl">Register Now</a>
    </div>
  </div>
</template>
<script>
export default {
  name: "RegisterButton",
  props: {
    registerUrl:{
      type: String,
      default: "",
    }
  },
};
</script>
