<template>
  <ion-app>
    <ion-router-outlet animated="false" />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet, useBackButton, isPlatform } from "@ionic/vue";
import { defineComponent } from "vue";
import { App as CapApp } from "@capacitor/app";
import { useAuth0 } from "@auth0/auth0-vue";
import { Browser } from "@capacitor/browser";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
// import { pushInit, unsubscribePush } from "./utils/push-notification";
import { redirectDeepLinkPath } from "@/utils/redirect-uri";
import router from "./router";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
  },

  setup() {
    useBackButton(10, () => {
      // leave empty to not trigger any back button event
    });

    const { handleRedirectCallback, getAccessTokenSilently, isAuthenticated } =
      useAuth0();
    const isNative = isPlatform("android") || isPlatform("ios");
    const isNativeBrowser = isPlatform("mobileweb");

    CapApp.addListener("appUrlOpen", async ({ url }) => {
      console.log("before capapp");
      const needRedirect = redirectDeepLinkPath(router, url);
      if (needRedirect) {
        return;
      }

      let appState = null;
      if (
        url.includes("state") &&
        (url.includes("code") || url.includes("error"))
      ) {
        try {
          const redirect = await handleRedirectCallback(url);
          appState = redirect.appState;
          console.log("appState", appState);
        } catch (e) {
          // if (e.error === "access_denied") {
          //   if (e.error_description === 'access 2022') {
          //     console.log("forward to 2022-access")
          //     window.location.href = "/2022-access";
          //   } else if (e.error_description === 'access denied') {
          //     console.log("forward to access-denied")
          //     window.location.href = "/access-denied";
          //   }
          // } else {
          //   throw e;
          // }
          console.log(e)
        }
        console.log("after redirect capapp");
      }
      try {
        await Browser.close();
      } catch (error) {
        console.log(error);
      }

      if (url.includes("/callback")) {
        console.log("login done...");
        //init push notifications here
        // if (isNative && !isNativeBrowser) {
        //   pushInit();
        // }
        if(isNative && !isNativeBrowser) {

          /**
           * Platform: Web/Android/iOS
           * Sets whether analytics collection is enabled for this app on this device.
           * @param name - enabled - boolean true/false
           * @returns void
           */
           FirebaseAnalytics.setCollectionEnabled({
            enabled: true,
          });

          if (appState && appState.fullPathObj) {
            router.push({
              path: appState.fullPathObj.path,
              query: appState.fullPathObj.query,
              hash: appState.fullPathObj.hash,
            });
          }
        }
      } else if (url.includes("/logout")) {
        console.log("logout done...");
        window.location.href = "/";
        // unsubscribePush();
        /**
         * Platform: Web/Android/iOS
         * Sets whether analytics collection is enabled for this app on this device.
         * @param name - enabled - boolean true/false
         * @returns void
         */
         FirebaseAnalytics.setCollectionEnabled({
          enabled: false,
        });
      }
    });
    console.log("Capapp: " + isAuthenticated.value);
  },
});
</script>
