<template>
    <ion-page>
      <ion-content fullscreen="true" class="eventpage">
        <main>
          <div class="w-full h-full min-h-screen" :class="{ 'bg-[#3C3A36] text-white': !isLightMode , 'bg-[#F6F4F0] text-black': isLightMode }">
              <slot class="flex-1 pb-16 lg:pb-0" test="test"> </slot>
            </div>
        </main>
      </ion-content>
    </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import { mapState } from "vuex";

export default {
  name: "EventPageLayout",
  components: {
    IonPage,
    IonContent,
  },
  computed: {
    ...mapState("eventPage", ["isLightMode"]),
  },
};
</script>