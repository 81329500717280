import { Smartlook as SmartlookMobile } from "@awesome-cordova-plugins/smartlook";
import Smartlook from "smartlook-client";
import { isNativeNotWeb } from "@/utils/platform-check";

// This is called only upon refetching profile, which happens only once till refresh/ login
export const startSmartlook = (
  email,
  name,
  userId,
) => {

  if (!isNativeNotWeb()) {
    console.log("Not Native");

    Smartlook.init(process.env.VUE_APP_SMARTLOOK_API_KEY);
    console.log("after init");
    // To restart the session, use anonymize
    Smartlook.anonymize();

    console.log("after set event");
  } else {
    console.log("Native");

    SmartlookMobile.setProjectKey({
      key: process.env.VUE_APP_SMARTLOOK_API_KEY,
    });
    SmartlookMobile.start();

    console.log("after init");
    SmartlookMobile.eventTrackingEnableAll();
    SmartlookMobile.setEventTrackingInteractionUserStatus({
      isEnabled: true,
    });
    SmartlookMobile.setEventTrackingInteractionRageClickStatus({
      isEnabled: true,
    });
    // To restart the session, use anonymize
    // Smartlook.anonymize();

    console.log("after set event");
    // Identify session
    SmartlookMobile.setUserIdentifier({
      identifier: `${userId}-${Math.ceil(Math.random() * 10000)}`,
    });
    SmartlookMobile.setUserName({ name: name });
    SmartlookMobile.setUserEmail({ email: email });
    console.log("after set email");
  }
};
